* {
  box-sizing: border-box;
}

body {
  line-height: 1.4;
  color: #43425d;
  max-width: 100%;
  background-color: #f6f8f9;
}

a {
  color: #ffc107;
}

.footer {
  width: 100%;
  display: flex;
  background-color: #43425d;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  padding: 20px;

  a {
    padding: 4px 18px;
    color: #fff;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    border-right: 1px solid #fff;

    @media screen and (max-width: 769px) {
      padding: 4px 10px;
      margin: 0;
    }

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      margin-right: 0;
      border-right: 0;
      padding-right: 0;
    }
  }

  @media screen and (max-width: 769px) {
    padding: 12px;
    font-size: 12px;
  }
}

.container {
  width: 100%;
  min-height: calc(100vh - 67px);
  max-width: 1280px;
  padding: 30px 20px;
  margin: 0 auto;
}

.logo {
  display: block;
  width: 100%;
  max-width: 300px;
  margin: 40px auto 60px;
}

.logo--main {
  margin-top: 150px;
  width: 100%;
  max-width: 500px;
  object-fit: contain;
}

li {
  margin-bottom: 8px;
}

.home {
  @media screen and (max-width: 769px){
    height: calc(100vh - 52px);
  }
}