$darkMain: #43425D;
$main: #605f6b;
$orange: #F9B000;
$background: #F7F3EF;
$grey: #848484;

* {
  box-sizing: border-box;
}

.h-100 {
  overflow: hidden;
  margin: 0 auto 0;
  padding: 15vh 0 0;
  height: 100vh;

  @media screen and (max-width: 768px){
    padding: 4vh 0 0;
  }
}

.modal {
  border-radius: 15px;
  width: 100%;
  max-width: 400px;
  background-color: rgba($background, 0.5);
  border: 1px solid rgba($orange, 0.9);
  padding: 20px 40px 40px;
  margin: 0 auto;
  color: $main;
  text-align: center;

  @media screen and (max-width: 769px) {
    padding-top: 10px;
  }
}

.mb-2 {
  color: $darkMain;
  line-height: 1;
  font-weight: bold;
  font-size: 32px;
  margin-bottom: 26px;

  @media screen and (max-width: 769px) {
    font-size: 28px;
  }
}

.logo {
  max-width: 330px;
  width: 100%;
  margin: 0 auto!important;

  @media screen and (max-width: 768px){
    max-width: 230px;
  }
}

.copyright {
  margin-top: 40px;
}

.input {
  display: block;
  border-radius: 4px;
  margin: 0 auto 12px;
  box-shadow: none;
  border: 1px solid $main;
  height: 36px;
  width: 100%;
  padding: 4px 12px;
  font-size: 14px;
  max-width: 250px;
}

.button {
  background-color: $darkMain;
  border: 0;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  padding: 12px;
  width: 200px;
  cursor: pointer;
  border-radius: 4px;
  margin-top: 10px;
  transition: all 0.25s;

  &:hover {
    background-color: darken($darkMain, 7);
  }
}

.Toastify__toast {
  border-radius: 4px;
}

.activate-page {
  padding: 15vh 20px 0;
  text-align: center;

  .footer {
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    padding: 20px 0;
    left: 50%;
    transform: translateX(-50%);
    max-width: 100vw;
  }
}